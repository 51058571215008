:root {
	--black: #000000;
	--red: #f00;
	--off-black: #1d1d1d;
	--white: #ffffff;
	--off-white: #fcfcfc;
	--gray: #f1f1f1;
	--gray-hover: #ededed;
	--midgray: #c4c4c4;
	--darkgray: #666666;
	--icongray: #ababab;

	--primary-color: #e4f6f3;
	--primary-color-hover: #d5f1ec;
	--primary-color-fg: var(--black);
	--secondary-color: #f1f1f1;
	--secondary-color-hover: #ededed;
	--secondary-color-fg: var(--black);
	--danger-color: #f0abab;
	--danger-color-hover: #ed9797;
	--danger-color-fg: var(--black);
	--link-color: #243633;

	--logo-color: var(--black);
	--background-color: var(--white);
	--text-color: var(--black);
	--divider-color: rgba(130, 130, 130, 0.3);
	--action-link-color: var(--darkgray);
	--label-color: var(--off-black);
	--default-color: var(--gray);
	--default-color-hover: var(--gray-hover);
	--button-text-color: var(--black);
	--button-text-color-hover: var(--off-black);
	--playhead-color: #d9d9d9;
	--border-color: #dedede;
	--placeholder-color: rgba(127, 127, 127, 0.6);
	--field-focus-color: var(--midgray);
	--grid-line-color: rgba(170, 170, 170, 0.22);
	--grid-legend-color: rgba(0, 0, 0, 0.5);

	--regular: 400;
	--semibold: 500;
	--bold: 600;

	--medium-page-width: 1040px;
	--content-space-top: 0px;
	--content-width: var(--medium-page-width);
	--page-pad-x: 40px;
	--header-height: 100px;
	--footer-height: 120px;
	--slot-radius: 10px;
	--button-large-height: 60px;
	--button-size: 25px;

	--sans: 'Roboto', system-ui, sans-serif;
	--title-large: var(--semibold) 50px/55px var(--sans);
	--title-medium: var(--semibold) 30px/40px var(--sans);
	--title-small: var(--semibold) 20px/20px var(--sans);
	--title-tiny: var(--semibold) 16px/20px var(--sans);
	--body-large: var(--regular) 20px/1.6 var(--sans);
	--body-regular: var(--regular) 16px/25px var(--sans);
	--body-small: var(--regular) 14px/25px var(--sans);
	--body-tiny: var(--regular) 14px/15px var(--sans);
	--footer-font: var(--semibold) 16px/20px var(--sans);
	--clock-font: var(--semibold) 16px/20px var(--sans);
	--participant-clock-font: var(--semibold) 30px/30px var(--sans);
	--label-medium: var(--title-tiny);
	--label-small: var(--regular) 14px/20px var(--sans);

	--shadow-clickable: 0px 0.5px 1px rgba(0, 0, 0, 0.25);

	--z-app-header: 200;
	--z-overlay-editor: 500;
}

@media (max-height: 700px) {
	:root {
		--footer-height: 80px;
		--header-height: 60px;
		--button-large-height: 44px;
	}
}

@media (max-height: 500px) {
	:root {
		--footer-height: 10px;
	}
}

html,
body {
	margin: 0;
	padding: 0;
	font: var(--body-regular);
	height: 100%;
	color: var(--text-color);
	line-height: 1.5;
	background: var(--background-color);
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#app {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

a {
	color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	line-height: 1;
	font-weight: var(--semibold);
}

p {
	margin: 0;
}

ul,
li {
	margin: 0;
	padding: 0;
	list-style-position: inside;
}

pre {
	-moz-tab-size: 2;
	  -o-tab-size: 2;
	     tab-size: 2;
}

::-webkit-scrollbar {
	background: lightgray !important;
	border-radius: 10px !important;
	width: 5px !important;
	height: 5px !important;
}

::-webkit-scrollbar-thumb {
	background: #000 !important;
	border-radius: 10px !important;
	width: 5px !important;
	height: 5px !important;
}

.InlineEdit_ba2d06 {
	font: inherit;
	margin: 0px 15px;
}
.InlineEdit_ba2d06:focus-within {
	background: var(--gray);
	border-radius: 5px;
	padding: 0.15em 0.25em;
	outline: none;
}
.InlineEdit__field_ba2d06:focus {
	outline: none;
}
@media (prefers-color-scheme: dark) {
	.InlineEdit_ba2d06:focus-within {
		background: var(--darkgray);
	}
}

.ErrorBox_d34b17 {
	background: #1d1166;
	color: white;
	border-radius: 5px;
	padding: 10px 15px;
}
.ErrorBox__message_d34b17 {
	font-weight: bold;
}
.ErrorBox__actions_d34b17 {
	font-weight: bold;
	margin-top: 10px;
	font-size: 18px;
	text-decoration: underline;
}


.BaseButtonError_bf9f7a {
	position: absolute;
}

.BaseButton_bf9f7a {
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	font-family: inherit;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	background: transparent;
	padding: 0;
	border: none;
	text-decoration: none;
	vertical-align: text-bottom;
}

.BaseButton__children_bf9f7a {
	opacity: 0;
	transition: opacity 150ms ease;
}

.BaseButton__children_bf9f7a:only-child {
	opacity: 1;
}

.BaseButton__message_bf9f7a {
	position: absolute;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.BaseButton--isLoading_bf9f7a {
	opacity: 0.7;
	-webkit-animation: loading-fade_bf9f7a 2s infinite;
	        animation: loading-fade_bf9f7a 2s infinite;
	pointer-events: none;
}

@-webkit-keyframes loading-fade_bf9f7a {
	0%,
	100% {
		opacity: 0.7;
	}
	50% {
		opacity: 0.25;
	}
}

@keyframes loading-fade_bf9f7a {
	0%,
	100% {
		opacity: 0.7;
	}
	50% {
		opacity: 0.25;
	}
}

.BaseButton_bf9f7a:focus {
	outline: none;
}

.BaseButton_bf9f7a[disabled] {
	opacity: 0.3;
	pointer-events: none;
}

.Button_bf9f7a {
	box-sizing: border-box;
	text-align: center;
	white-space: nowrap;
}

.Button_bf9f7a:focus {
	-webkit-animation: button-click_bf9f7a 150ms ease;
	        animation: button-click_bf9f7a 150ms ease;
}

@-webkit-keyframes button-click_bf9f7a {
	0% {
		transform: scale(0.98);
	}
}

@keyframes button-click_bf9f7a {
	0% {
		transform: scale(0.98);
	}
}

.Button--round_bf9f7a {
	border-radius: 20px;
	min-height: 40px;
	padding: 10px 25px;
	font-weight: var(--semibold);
	font-size: 16px;
	line-height: 20px;
	box-shadow: var(--shadow-clickable);
}

.Button--wide_bf9f7a {
	border-radius: 14px;
	min-height: 38px;
	padding: 9px 25px;
	font-weight: var(--semibold);
	font-size: 18px;
	line-height: 20px;
	box-shadow: var(--shadow-clickable);
}

.Button--square_bf9f7a {
	border-radius: 4px;
	min-height: 40px;
	padding: 4px 30px;
	font-weight: var(--semibold);
	font-size: 16px;
	line-height: 20px;
	box-shadow: var(--shadow-clickable);
}

.Button--large_bf9f7a {
	border-radius: 30px;
	min-height: var(--button-large-height);
	padding: 5px 30px;
	font-weight: var(--semibold);
	font-size: 20px;
	line-height: 20px;
	box-shadow: var(--shadow-clickable);
	display: inline-flex;
	align-items: center;
}

.Button--default_bf9f7a {
	background: var(--default-color);
	color: var(--button-text-color);
}

.Button--default_bf9f7a:hover {
	background: var(--default-color-hover);
	color: var(--button-text-color-hover);
}

.Button--primary_bf9f7a {
	background: var(--primary-color);
	color: var(--primary-color-fg);
}

.Button--primary_bf9f7a:hover {
	background: var(--primary-color-hover);
	color: var(--primary-color-fg);
}

.Button--secondary_bf9f7a {
	background: var(--secondary-color);
	color: var(--secondary-color-fg);
}

.Button--secondary_bf9f7a:hover {
	background: var(--secondary-color-hover);
	color: var(--secondary-color-fg);
}

.Button--danger_bf9f7a {
	background: var(--danger-color);
	color: var(--danger-color-fg);
}

.Button--danger_bf9f7a:hover {
	background: var(--danger-color-hover);
	color: var(--danger-color-fg);
}

.Button--has-icon_bf9f7a::after {
	content: '';
	width: 20px;
	height: 20px;
	display: inline-block;
	margin-left: 16px;
	vertical-align: bottom;
	background-size: 18px auto;
	background-repeat: no-repeat;
	background-position: center center;
}

.Link_bf9f7a {
	font-size: inherit;
	font-family: inherit;
	font-weight: inherit;
	line-height: inherit;
	vertical-align: baseline;
}

.Link--unstyled_bf9f7a {
	color: inherit;
}

.Link--default_bf9f7a {
	color: var(--link-color);
}

.Link--default_bf9f7a:hover {
	text-decoration: underline;
}

.Link--action_bf9f7a {
	text-decoration: underline;
	color: var(--action-link-color);
	font-weight: var(--semibold);
}

.Link--action_bf9f7a:hover {
	opacity: 0.8;
}

.Link--forward_bf9f7a {
	font-weight: var(--semibold);
	position: relative;
}

.Link--forward_bf9f7a:after,
.Link--forward-white_bf9f7a:after {
	content: '';
	display: inline-block;
	width: 0.75em;
	height: 0.75em;
	vertical-align: middle;
	background-size: 0.75em auto;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url(arrow-right.657d06a1.svg);
	margin-left: 10px;
}

.Link--forward_bf9f7a:before,
.Link--forward-white_bf9f7a:before {
	content: '';
	display: block;
	position: absolute;
	width: calc(100% - 24px);
	height: 2px;
	bottom: 0;
	left: 1px;
	background: currentColor;
	transform: scaleX(0);
	opacity: 0;
	transition: transform 200ms ease, opacity 150ms ease;
	transform-origin: left center;
}

.Link--forward_bf9f7a:hover:before {
	transform: scaleX(1);
	opacity: 1;
}

.Link--forward_bf9f7a:hover:after {
	-webkit-animation: link-forward-arrow_bf9f7a 300ms ease 130ms;
	        animation: link-forward-arrow_bf9f7a 300ms ease 130ms;
}

.Link--forward-white_bf9f7a:after {
	background-image: url(arrow-right-white.87708020.svg);
}

@-webkit-keyframes link-forward-arrow_bf9f7a {
	40% {
		transform: translateX(3px);
	}
}

@keyframes link-forward-arrow_bf9f7a {
	40% {
		transform: translateX(3px);
	}
}

.Link--back_bf9f7a {
	font-weight: var(--semibold);
	position: relative;
	color: var(--darkgray);
}

.Link--back_bf9f7a:before {
	content: '';
	display: inline-block;
	width: 12px;
	height: 12px;
	vertical-align: middle;
	background-size: 12px auto;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url(arrow-left.efa1fb22.svg);
	margin-right: 10px;
}

.Link--back_bf9f7a:after {
	content: '';
	display: block;
	position: absolute;
	width: calc(100% - 24px);
	height: 2px;
	bottom: 0;
	right: 1px;
	background: currentColor;
	transform: scaleX(0);
	opacity: 0;
	transition: transform 200ms ease, opacity 150ms ease;
	transform-origin: right center;
}

.Link--back_bf9f7a:hover:after {
	transform: scaleX(1);
	opacity: 1;
}

.Link--back_bf9f7a:hover:before {
	-webkit-animation: link-back-arrow_bf9f7a 300ms ease 130ms;
	        animation: link-back-arrow_bf9f7a 300ms ease 130ms;
}

@-webkit-keyframes link-back-arrow_bf9f7a {
	40% {
		transform: translateX(-3px);
	}
}

@keyframes link-back-arrow_bf9f7a {
	40% {
		transform: translateX(-3px);
	}
}

.IconButton_bf9f7a {
	width: var(--button-size);
	height: var(--button-size);
	border-radius: 50%;
	background-size: 14px auto;
	background-position: center center;
	background-repeat: no-repeat;
	text-indent: -999px;
	overflow: hidden;
	background-color: var(--off-white);
	transition: background-color 150ms ease;
	position: relative;
}

.IconButton_bf9f7a:hover {
	background-color: var(--gray);
}

.IconButton_bf9f7a:after {
	content: '';
	display: inline-block;
}

.RoundButton_bf9f7a {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	flex-shrink: 0;
	background-size: 14px auto;
	background-position: center center;
	background-repeat: no-repeat;
	overflow: hidden;
	transition: background-color 150ms ease;
	background-color: var(--gray);
}

.RoundButton--has-children_bf9f7a span svg {
	margin: auto;
	padding: auto;
	font-size: 15px;
}

.RoundButton--size-small_bf9f7a {
}

.RoundButton--size-auto_bf9f7a {
	width: 2em;
	height: 2em;
	background-size: 1em auto;
}

.RoundButton--size-48_bf9f7a {
	width: 48px;
	height: 48px;
	background-size: 32px auto;
}

.RoundButton_bf9f7a:hover {
	background-color: var(--gray-hover);
}

.ActionLink_bf9f7a {
	background: var(--secondary-color);
	color: var(--secondary-color-fg);
	min-height: 30px;
	font-size: 14px;
	font-weight: var(--semibold);
	line-height: 20px;
	padding: 5px 10px;
	border-radius: 4px;
	box-shadow: var(--shadow-clickable);
	text-decoration: none;
}

.ActionLink_bf9f7a:hover {
	background: var(--secondary-color-hover);
}

.ActionLink_bf9f7a:active {
	transform: translateY(1px);
}

.ActionLink--has-icon_bf9f7a::after {
	content: '';
	display: inline-block;
	margin-left: 10px;
	width: 16px;
	height: 16px;
	background-position: center center;
	background-size: auto 16px;
	background-repeat: no-repeat;
	vertical-align: middle;
}

.icon-bg--link_bf9f7a,
.icon--link_bf9f7a::before,
.icon--link_bf9f7a::after {
	background-image: url(link.c0ad62a1.svg);
}

.icon-bg--edit_bf9f7a,
.icon--edit_bf9f7a::before,
.icon--edit_bf9f7a::after {
	background-image: url(edit.01f1008c.svg);
}

.icon-bg--people_bf9f7a,
.icon--people_bf9f7a::before,
.icon--people_bf9f7a::after {
	background-image: url(people.a6d04059.svg);
}

.icon-bg--add-person_bf9f7a,
.icon--add-person_bf9f7a::before,
.icon--add-person_bf9f7a::after {
	background-image: url(add-person.feba20eb.svg);
}

.icon-bg--archive_bf9f7a,
.icon--archive_bf9f7a::before,
.icon--archive_bf9f7a::after {
	background-image: url(archive.b69377cf.svg);
}

.icon-bg--view_bf9f7a,
.icon--view_bf9f7a::before,
.icon--view_bf9f7a::after {
	width: 12px;
	background-image: url(view.da741d7b.svg);
}

.icon-bg--more-v_bf9f7a,
.icon--more-v_bf9f7a::before,
.icon--more-v_bf9f7a::after {
	background-size: 20px auto;
	background-image: url(more-v.377a4d11.svg);
}

.icon-bg--more-h_bf9f7a,
.icon--more-h_bf9f7a::before,
.icon--more-h_bf9f7a::after {
	background-size: 20px auto;
	background-image: url(more-h.356cbdf1.svg);
}

.icon-bg--remove_bf9f7a,
.icon--remove_bf9f7a::before,
.icon--remove_bf9f7a::after {
	background-size: 15px auto;
	background-position: center center;
	background-image: url(remove.10bbbc5b.svg);
}

.icon-bg--next_bf9f7a,
.icon--next_bf9f7a::before,
.icon--next_bf9f7a::after {
	background-size: 15px auto;
	background-position: center center;
	background-image: url(next.83d8138f.svg);
}

.icon-bg--previous_bf9f7a,
.icon--previous_bf9f7a::before,
.icon--previous_bf9f7a::after {
	background-size: 15px auto;
	background-position: center center;
	background-image: url(previous.2f248374.svg);
}

.icon-bg--arrow-left_bf9f7a,
.icon--arrow-left_bf9f7a::before,
.icon--arrow-left_bf9f7a::after {
	background-size: 13px auto;
	background-position: center center;
	background-image: url(arrow-left.efa1fb22.svg);
}

.icon-bg--arrow-right_bf9f7a,
.icon--arrow-right_bf9f7a::before,
.icon--arrow-right_bf9f7a::after {
	background-size: 13px auto;
	background-position: center center;
	background-image: url(arrow-right.657d06a1.svg);
}

.icon-bg--duplicate_bf9f7a,
.icon--duplicate_bf9f7a::before,
.icon--duplicate_bf9f7a::after {
	background-size: 16px auto;
	background-position: center center;
	background-image: url(duplicate.0b8c5275.svg);
}

.icon-bg--trash_bf9f7a,
.icon--trash_bf9f7a::before,
.icon--trash_bf9f7a::after {
	background-size: 14px auto;
	background-position: center center;
	background-image: url(trash.d10ef64c.svg);
}

.icon-bg--sound-on_bf9f7a,
.icon--sound-on_bf9f7a::before,
.icon--sound-on_bf9f7a::after {
	background-size: 26px auto;
	background-position: center center;
	background-image: url(sound-on.c5bed50c.svg);
}

.icon-bg--sound-off_bf9f7a,
.icon--sound-off_bf9f7a::before,
.icon--sound-off_bf9f7a::after {
	background-size: 26px auto;
	background-position: center center;
	background-image: url(sound-off.752dfcc4.svg);
}

.icon-bg--time_bf9f7a,
.icon--time_bf9f7a::before,
.icon--time_bf9f7a::after {
	background-size: 24px auto;
	background-position: center center;
	background-image: url(time.b7659006.svg);
}

.icon-bg--printer_bf9f7a,
.icon--printer_bf9f7a::before,
.icon--printer_bf9f7a::after {
	background-size: 24px auto;
	background-position: center center;
	background-image: url(printer.31bec2f9.svg);
}

.icon-bg--user_bf9f7a,
.icon--user_bf9f7a::before,
.icon--user_bf9f7a::after {
	background-size: 24px auto;
	background-position: center center;
	background-image: url(user.2f0ce399.svg);
}

.icon-bg--users_bf9f7a,
.icon--users_bf9f7a::before,
.icon--users_bf9f7a::after {
	background-size: 24px auto;
	background-position: center center;
	background-image: url(users.0158ae84.svg);
}

.icon-bg--right-curved-arrow_bf9f7a,
.icon--right-curved-arrow_bf9f7a::before,
.icon--right-curved-arrow_bf9f7a::after {
	background-color: #000;
	background-size: 26px auto;
	background-position: center center;
	background-image: url(right-curved-arrow.fe4fec7a.svg);
}

.icon-bg--location_bf9f7a,
.icon--location_bf9f7a::before,
.icon--location_bf9f7a::after {
	background-size: 26px auto;
	background-position: center center;
	background-image: url(location.987be420.svg);
}

.icon-bg--google-meeting_bf9f7a,
.icon--google-meeting_bf9f7a::before,
.icon--google-meeting_bf9f7a::after {
	background-size: 260px auto;
	background-position: center center;
	background-image: url(google-meeting.d88b2a39.svg);
}

.icon-bg--google_bf9f7a,
.icon--google_bf9f7a::before,
.icon--google_bf9f7a::after {
	background-size: 260px auto;
	background-position: center center;
	background-image: url(google.43f185e8.svg);
}

.icon-bg--zoom_bf9f7a,
.icon--zoom_bf9f7a::before,
.icon--zoom_bf9f7a::after {
	background-size: 260px auto;
	background-position: center center;
	background-image: url(zoom.edf41737.svg);
}

.AppHeader_01622e {
	position: fixed;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	height: var(--header-height);
	top: 0;
	left: 0;
	width: 100%;
	height: 10%;
	z-index: var(--z-app-header);
	pointer-events: none;
}
.logo_01622e {
	padding: 0 var(--page-pad-x);
	margin-right: auto;
	display: inline-block;
	height: 30px;
	font-size: 30px;
	font-weight: var(--semibold);
	line-height: 30px;
	pointer-events: all;
}
.logo_01622e:hover {
	text-decoration: none;
}
.profile_01622e {
	display: flex;
	margin-left: auto;
	pointer-events: all;
	padding: 0 var(--page-pad-x);
}
.middle_01622e {
	-ms-grid-row-align: stretch;
	    align-self: stretch;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: all;
	padding: 10px 0;
	width: 90%;
}
.middle_01622e * {
	margin-inline: 4px;
}
.start_01622e {
	justify-content: center;
	align-items: center;
	pointer-events: all;
	margin-top: 5px;
	width: 5%;
}
.sessionTitle_01622e {
	cursor: default;
	font-size: 40px;
	line-height: 1em;
	font-weight: var(--semibold);
}
.backHomeBtn_01622e {
	margin-left: 40px;
}
@media (max-height: 700px) {
	.sessionTitle_01622e {
		font-size: 30px;
	}

	.AppHeader_01622e {
		margin-top: 15px;
	}
}
@media (max-height: 500px) {
	.sessionTitle_01622e {
		font-size: 25px;
	}
}
@media (max-width: 900px) {
}

.Main_affab2 {
	flex: 1;
	display: flex;
	align-items: flex-start;
	padding-top: var(--header-height);
}
.Main__content_affab2 {
	max-width: var(--content-width);
	flex: 1;
	display: flex;
	align-items: flex-start;
	padding: 0 var(--page-pad-x);
	position: relative;
}
.Loader_affab2 {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: var(--header-height);
}
.Loader__content_affab2 {
	max-width: var(--content-width);
	flex: 1;
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	align-items: center;
	justify-content: center;
	position: relative;
	font-size: 30px;
	-webkit-animation: loader-fade-in_affab2 300ms ease 100ms backwards;
	        animation: loader-fade-in_affab2 300ms ease 100ms backwards;
	margin-bottom: 10vh;
}
@-webkit-keyframes loader-fade-in_affab2 {
	0% {
		opacity: 0;
	}
}
@keyframes loader-fade-in_affab2 {
	0% {
		opacity: 0;
	}
}
.Content--layout-fill-and-center_affab2 {
	flex: 1;
	display: flex;
	-ms-grid-row-align: stretch;
	    align-self: stretch;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}
@media (min-width: 750px) {
	.Main__content_affab2 {
		margin: var(--content-space-top) auto 0;
	}
	.Content_affab2 {
		flex: 55;
	}
	.Side_affab2 {
		flex: 45;
		padding: var(--space-top) 0 var(--space-bottom) 90px;
	}
}
@media (max-width: 900px) {
	.Main__content_affab2 {
		display: flex;
		flex-direction: column;
		row-gap: 50px;
	}
	.Content_affab2,
	.Side_affab2 {
		flex: auto;
	}
}

.SessionList_ec4887 {
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: left;
	margin-top: 28px;
	margin-bottom: 13px;
}
.SessionList--preview_ec4887 {
}
.SessionList--full_ec4887 {
	display: flex;
	flex-direction: column;
}
.SessionListItem_ec4887 {
	border-radius: 15px;
	transition: background-color 150ms ease, box-shadow 150ms ease;
	margin-left: calc(5px - var(--space-x));
	margin-right: calc(0px - var(--space-x));
}
.SessionListItem--preview_ec4887 {
	--title-font: var(--title-tiny);
	--info-font: var(--body-small);
	margin-bottom: 19px;
	height: 53px;
}
.SessionListItem--full_ec4887 {
	--title-font: var(--title-small);
	--info-font: var(--body-regular);
	--space-x: 30px;
	--space-y: 20px;
	row-gap: 10px;
}
.SessionListItem--preview_ec4887 + .SessionListItem--preview_ec4887 {
}
.SessionListItem--full_ec4887 + .SessionListItem--full_ec4887 {
	margin-top: 20px;
}
.SessionListItem_ec4887:hover {
}
.SessionListItem__link_ec4887:after {
	content: '';
	grid-row: 1 / -1;
	-ms-grid-column: 2;
	grid-column: 2;
	display: inline-block;
	width: 30px;
	height: 100%;
	background-size: 14px auto;
	background-repeat: no-repeat;
	background-position: center right;
	background-image: url(chevron-right.5e090e2e.svg);
	transition: transform 150ms ease;
}
.SessionListItem__link_ec4887:hover:after {
	transform: translateX(3px);
}
.SessionListItem__link_ec4887 {
	text-decoration: none;
	display: -ms-grid;
	display: grid;
	-ms-grid-rows: auto auto auto;
	grid-template-rows: auto auto auto;
	-ms-grid-columns: 1fr auto;
	grid-template-columns: 1fr auto;
	padding: var(--space-y) var(--space-x);
	gap: inherit;
	cursor: pointer;
}
.SessionListItem__link_ec4887:hover {
	text-decoration: none;
}
.SessionListItem__title_ec4887 {
	text-align: left;
	font: var(--title-font);
	font-size: 16px;
	font-weight: 700;
	-ms-grid-row: 1;
	grid-row: 1;
	-ms-grid-column: 1;
	grid-column: 1;
}
.SessionListItem__info_ec4887 {
	font: var(--info-font);
	font-size: 14px;
	-ms-grid-row: 2;
	grid-row: 2;
	-ms-grid-column: 1;
	grid-column: 1;
}
.SessionListItem__actions_ec4887 {
	-ms-grid-row: 3;
	grid-row: 3;
	-ms-grid-column: 1;
	grid-column: 1;
	cursor: default;
	display: flex;
	grid-gap: 20px;
}
.SessionListItem__actionItem_ec4887 {
}

.OverlayCardWrap_de770c {
	position: fixed;
	background: rgba(255, 255, 255, 0.75);
	border: 1px solid var(--border-color);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	z-index: var(--z-overlay-editor);
	--pad-x: 30px;
	--pad-y: 35px;
}

.OverlayCard_de770c {
	--background-color: var(--white);
	background: var(--background-color);
	z-index: calc(var(--z-app-header) + 5);
	display: flex;
	align-items: start;
	justify-content: center;
	box-shadow: 0 1px 50px rgba(0, 0, 0, 0.2);
	padding: 0 0;
	width: 100%;
	margin-top: 5vh;
	max-width: 70vw;
	max-height: 90vh;
	border-radius: 10px;
	background: var(--white);
	border: 1px solid var(--gray);
}

.OverlayCard__content_de770c {
	flex: 1;
	overflow: hidden;
}

@media (max-width: 800px) {
	.OverlayCard_de770c {
		margin-top: 0;
		margin-bottom: 0;
		max-width: 100vw;
		height: 100vh;
		max-height: 100vh;
	}
}

@media (max-height: 500px) {
	.OverlayCard_de770c {
		margin-top: 0;
		height: 100vh;
		max-height: 100vh;
	}
}

.OverlayEditor_1e5379 {
	display: flex;
	flex-direction: column;
}

.OverlayEditor__header_1e5379 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background: var(--background-color);
	z-index: var(--z-overlay-editor);
	padding: 15px var(--pad-x) 5px;
	margin-bottom: 5px;
}

.OverlayEditor__header_1e5379 *:nth-child(n + 2) * {
	margin-inline: 10px;
}

.OverlayEditor__title_1e5379 {
	width: 75%;
	white-space: nowrap;
	flex-wrap: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	text-align: start;
	font: var(--title-medium);
	margin-right: 35px;
}

.OverlayEditor__title_center_1e5379 {
	width: 100%;
	-ms-grid-column-align: center;
	    justify-self: center;
	margin-right: 0;
}

.OverlayEditor__actions_1e5379 > * + * {
	margin: 30px;
}

.OverlayEditor__content_1e5379 {
	padding: 0 27px;
	height: 90%;
	flex-grow: 1;
}

.OverlayEditor__footer_1e5379 {
	box-sizing: border-box;
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
	background: var(--background-color);
	padding: 0px 25px 10px 25px;
}

.OverlayEditor__footer__actionBtn_1e5379 {
	width: 100%;
	margin-top: 15px;
}

.OverlayEditor__footer__actionBtn_1e5379 > * {
	margin: 0px 10px 0px 10px;
	float: right;
}

.OverlayEditor__custombtn_1e5379 {
	margin-top: 8px;
	float: left;
}

.TextArea_221226 {
	position: relative;
	--line-height: 1.6em;
	background: transparent;
	font-size: inherit;
	line-height: inherit;
	resize: none;
	font-family: inherit;
	font-size: inherit;
	line-height: var(--line-height);
	flex: 1;
	padding: 0;
	box-sizing: border-box;
	white-space: pre-line;
	display: block;
	-moz-tab-size: 4;
	  -o-tab-size: 4;
	     tab-size: 4;
	width: 100%;
	padding: 15px;
	border: 1px solid var(--border-color);
	border-radius: 5px;
}
.TextArea_221226:not(:last-child) {
	margin-bottom: 30px;
}
.TextArea_221226:focus {
	outline: none;
}

.TextInput_bea001 {
	position: relative;
	--line-height: 1.6em;
	background: transparent;
	font-size: inherit;
	line-height: inherit;
	resize: none;
	font-family: inherit;
	font-size: inherit;
	line-height: var(--line-height);
	flex: 1;
	padding: 0;
	box-sizing: border-box;
	white-space: pre-line;
	display: block;
	-moz-tab-size: 4;
	  -o-tab-size: 4;
	     tab-size: 4;
	width: 100%;
	padding: 15px;
	border: 1px solid var(--border-color);
	border-radius: 5px;
}
.TextInput_bea001:not(:last-child) {
	margin-bottom: 30px;
}
.TextInput_bea001:focus {
	outline: none;
}
.error_bea001 {
	color: red;
	margin-left: 10px;
}

.Label_2910a9 {
	font-size: 16px;
	font-weight: var(--semibold);
	margin: 0 0 0.5em 5px;
}

.SessionBuilder_9bebc1 {
	flex: 1;
}

.SessionBuilder__sectionTitle_9bebc1{
	margin: 8px 8px 20px 8px;
}

.SessionBuilder__addButton_9bebc1{
	float: right;
}

.SessionBuilder__loading_9bebc1{
	text-align: center;
}

.Table_9bebc1 {
	width: 100%;
	padding-bottom: 10px;
	padding-left: 5px;
	table-layout:fixed;
}

.Table__row_9bebc1 {
	height: 40px;
	word-wrap:break-word;
}

.Table__head_9bebc1 {
	text-align: center;
}

.Table__head_9bebc1:nth-child(1) {
	text-align: start;
}

.Table__row_data_9bebc1 {
	text-align: center;
}

.Table__row_data_9bebc1:nth-child(1) {
	text-align: start;
}

.Table__row_data_9bebc1:nth-child(3) {
	text-align: end;
}


.HomePage_4331cf {
	--space-top: 0px;
	--space-bottom: 30px;
	--content-space-top: 0;
}

.HomePage__intro_4331cf {
	padding: 16.42% 4.84% 11.13% 2.44%;
}

.HomePage_4331cf hr {
	background: var(--divider-color);
	width: 1px;
	-ms-grid-row-align: stretch;
	    align-self: stretch;
	margin: 0;
	border: none;
}

.HomePage__introTitle_4331cf,
.HomePage__sessionsTitle_4331cf {
	color: #1d1166;
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 38px;
	line-height: 45px;
	width: 100%;
	height: 49px;
}

.HomePage__introTitle_4331cf {
	margin-bottom: 37px;
}

.HomePage__introText_strong_p_4331cf {
	font-style: normal;
	font-weight: 700;
}

.HomePage__sessionText_4331cf {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 17px;
	line-height: 20px;
	height: 53px;
	display: flex;
	align-items: center;
	margin-top: 10px;
}

.HomePage__introText_4331cf {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 19px;
	line-height: 22px;
}

.HomePage__introText_4331cf p + p {
	margin-top: 20px;
}

.HomePage__sessions_4331cf {
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	max-width: 560px;
	margin: -20px 0px 0px 0px !important;
	padding: 48px 36px 0 0;
}

.HomePage__sessionsCta_4331cf {
	margin-top: 50px;
	font-weight: 700;
}

.HomePage__introCta_4331cf {
}

.HomePage__adminActions_4331cf {
	margin-bottom: 20px;
}

.HomePage__adminActions_4331cf a {
	margin: 5px;
}

.HomePage__verticalLine_4331cf {
	height: 100%;
}

@media (max-width: 900px) {

	.HomePage__verticalLine_4331cf {
		display: none;
	}
}

@media (min-width: 1500px) {
	.HomePage__introText_4331cf {
		min-width: 30.76vw;
		min-height: 27.71vh;
	}

	.HomePage__introTitle_4331cf,
	.HomePage__sessionsTitle_4331cf {
		white-space: nowrap;
	}

	.HomePage__introCta_4331cf {
		margin: 0 31.51% 0 26.48%;
	}
}

@media (max-width: 1500px) {
	.HomePage__introTitle_4331cf {
		margin-bottom: 80px;
	}
	.HomePage__introCta_4331cf {
		margin-top: 10%;
	}
}

@media (min-width: 900px) and (min-height: 850px) {
	.HomePage__intro_4331cf {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		width: 33.18vw;
		height: 56.44vh;
		padding-right: 15.08%;
		margin-left: 12.54vw;
		margin-top: 3vh;
	}
}

@media (min-width: 1500px) {
	.HomePage__introCta_4331cf {
		margin-top: 10%;
	}
	.HomePage__sessionText_4331cf {
		white-space: nowrap;
	}
}

.AuthPage_6d24ab {
	display: flex;
	justify-content: center;
	padding-top: 30vh;
	--content-width: 400px;
}

.AuthPage__input_6d24ab {
	display: block;
	margin-bottom: 1em;
}

@media (max-width: 500px) {
	.AuthPage_6d24ab {
		--content-width: auto;
	}
}

.AuthPage__message_6d24ab {
	margin-top: 1em;
	min-height: 2em;
	font-size: 20px;
	text-align: center;
}

.AuthPage__info_6d24ab {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: 15px;
}

.AuthPage__info_6d24ab p {
	text-align: center;
	font-size: 16px;
}

.AuthPage__error_6d24ab {
	margin-top: 1em;
}

.AuthPage__legalNotice_6d24ab {
	margin-top: 1em;
	margin-bottom: 1em;
	min-height: 2em;
	font-size: 14px;
}

.AuthPage__actions_6d24ab {
	display: flex;
	align-items: center;
	-moz-column-gap: 15px;
	     column-gap: 15px;
}

.AuthPage__swapMessage_6d24ab {
	font-size: 15px;
}

.AuthPage__forgotpassword_6d24ab {
	margin-bottom: 15px;
}

.SectionEditor_2f6d23 {
	flex: 1;
	min-height: 30vh;
}
.Checkbox_6cfe31 {
	display: flex;
	align-items: center;
}
.Checkbox__input_6cfe31 {
	width: 20px;
	height: 20px;
}
.Checkbox__label_6cfe31 {
	margin-left: 10px;
}

.SlotEditor_edff98 {
	flex: 1;
	min-height: 30vh;
}

.LineField_9c012d {
	position: relative;
	border: 1px solid var(--border-color);
	border-radius: 10px;
	display: flex;
	padding: 0 10px 0 0;
	transition: border-color 100ms ease, box-shadow 100ms ease;
	box-shadow: 0px 0px 0px rgba(127, 127, 127, 0);
}
.LineField_9c012d:focus-within {
	border-color: var(--field-focus-color);
	box-shadow: 0px 1px 3px 0 rgba(127, 127, 127, 0.2);
}
.LineField__input_9c012d {
	flex: 1;
	padding: 10px 10px 10px 15px;
	font-family: inherit;
	font-size: 16px;
	line-height: 30px;
	border: none;
	background: transparent;
}
.LineField__input_9c012d:focus {
	outline: none;
}
.LineField__input_9c012d::-moz-placeholder {
	color: var(--placeholder-color);
}
.LineField__input_9c012d:-ms-input-placeholder {
	color: var(--placeholder-color);
}
.LineField__input_9c012d::placeholder {
	color: var(--placeholder-color);
}
.LineField__submit_9c012d {
	width: auto;
	-ms-grid-row-align: center;
	    align-self: center;
}
.LineField__error_9c012d {
	position: absolute;
	top: 100%;
	left: 0;
}

.ParticipantsEditor_81012d {
	min-height: 50vh;
}
.ParticipantsEditor__header_81012d {
}
.ParticipantsEditor__sectionTitle_81012d {
	font: var(--title-small);
	margin-bottom: 20px;
	margin-top: 20px;
}
.ParticipantsEditor__header_81012d {
}
.Participant_81012d {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: auto 10px 1fr 10px auto;
	grid-template-columns: auto 1fr auto;
	-ms-grid-rows: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	    grid-template-areas:
		'thumb title actions'
		'thumb email actions';
	align-items: center;
	grid-column-gap: 10px;
}
.Participant_81012d + .Participant_81012d {
	margin-top: 20px;
}
.Participant__thumbnail_81012d {
	-ms-grid-row: 1;
	-ms-grid-row-span: 2;
	-ms-grid-column: 1;
	grid-area: thumb;
	width: 45px;
	height: 45px;
	box-sizing: border-box;
	background: var(--off-white);
	border: 1px solid var(--border-color);
	border-radius: 50%;
	overflow: hidden;
	grid-area: thumb;
}
.Participant__title_81012d {
	grid-area: title;
	font: var(--label-medium);
	display: flex;
	grid-column-gap: 5px;
}
.Participant__name_81012d {
	font: var(--label-medium);
}
.Participant__email_81012d {
	grid-area: email;
	font: var(--label-small);
}
.Participant__isOwner_81012d {
	height: 20px;
	line-height: 20px;
	display: inline-block;
	padding: 0 8px;
	margin: -1px 0 -1px;
	cursor: default;
	border-radius: 9px;
	font-size: 13px;
	letter-spacing: 0.02em;
	vertical-align: bottom;
	font-weight: var(--semibold);
	border: 1px solid var(--border-color);
}
.Participant__actions_81012d {
	grid-area: actions;
}
.Participant__actions_81012d > * + * {
	margin-left: 10px;
}

.SessionDetailsEditor_302a52 {
}

.InfoBox_4084d1 {
}

.DigitalClock_591456 {
	white-space: nowrap;
}
.DigitalClock__remaining_591456:after {
	content: '/';
	display: inline-block;
	margin: 0 0.25em;
}
.DigitalClock__total_591456 {
}
.DigitalClock__remaining_591456::-moz-selection, .DigitalClock__total_591456::-moz-selection {
	background: transparent;
}
.DigitalClock__remaining_591456::selection,
.DigitalClock__total_591456::selection {
	background: transparent;
}

.SessionClock_f9813a {
	cursor: default;
	margin-top: 10px;
	padding-top: 10px;
}

.ParticipantClock_f9813a {
	display: -ms-grid;
	display: grid;
	-ms-grid-rows: auto 1fr;
	grid-template-rows: auto 1fr;
	-ms-grid-columns: auto;
	grid-template-columns: auto;
	align-items: center;
	grid-row-gap: 15px;
}

.ParticipantClock__state_f9813a {
	font: var(--label-small);
	text-align: center;
}

.ParticipantClock__time_f9813a {
	font: var(--participant-clock-font);
	display: flex;
	justify-content: center;
}

.OwnerClock_f9813a {
	display: -ms-grid;
	display: grid;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
	/* grid-template-columns: 30px 40px 30px; */
	-ms-grid-columns: auto auto auto;
	grid-template-columns: auto auto auto;
	align-items: center;
	grid-column-gap: 15px;
	grid-row-gap: 15px;
}

.OwnerClock__button_f9813a {
	border-radius: 50%;
	background-position: center center;
	background-repeat: no-repeat;
	text-indent: 999px;
	overflow: hidden;
	background-color: var(--off-white);
	position: relative;
	background-size: 20px auto;
}

.OwnerClock__button_f9813a:hover {
}

.OwnerClock__button_f9813a:after {
	content: '';
	display: inline-block;
}

.OwnerClock__button--play_f9813a,
.OwnerClock__button--pause_f9813a {
	-ms-grid-row: 1;
	grid-row: 1;
	-ms-grid-column: 2;
	grid-column: 2;
	width: 40px;
	height: 40px;
	border: 2px solid var(--black);
}

.OwnerClock__button--play_f9813a {
	background-image: url(play.17667698.svg);
	background-position: 10px center;
}

.OwnerClock__button--pause_f9813a {
	background-image: url(pause.32f57cba.svg);
}

.OwnerClock__button--previous_f9813a,
.OwnerClock__button--next_f9813a {
	width: 30px;
	height: 30px;
}

.OwnerClock__button--previous_f9813a {
	-ms-grid-row: 1;
	grid-row: 1;
	-ms-grid-column: 1;
	grid-column: 1;
	background-image: url(previous.2f248374.svg);
}

.OwnerClock__button--next_f9813a {
	-ms-grid-row: 1;
	grid-row: 1;
	-ms-grid-column: 3;
	grid-column: 3;
	background-image: url(next.83d8138f.svg);
}

.OwnerClock__time_f9813a {
	-ms-grid-row: 2;
	grid-row: 2;
	-ms-grid-column: 1;
	-ms-grid-column-span: 3;
	grid-column: 1 / 4;
	font: var(--clock-font);
	display: flex;
	justify-content: center;
}

.JumpTo_5415bd {
}

.Footer_6f1740 {
	height: var(--footer-height);
	width: 100%;
	box-sizing: border-box;
	align-items: stretch;
	padding: 0 20px;
}
.Footer__infoBox_6f1740 {
	grid-area: info;
}
.Footer__playbackControl_6f1740 {
	grid-area: playback;
	display: flex;
	align-items: center;
}
.Footer__jumpTo_6f1740 {
	grid-area: jump;
}
.Footer__layoutControl_6f1740 {
	position: fixed;
	bottom: 0;
	right: 0;
}
.Footer__maximizeButton_6f1740,
.Footer__minimizeButton_6f1740 {
	margin-left: auto;
	width: 30px;
	height: 30px;
	border-radius: 3px;
	background-size: 16px auto;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url(more-v.377a4d11.svg);
	text-indent: 999px;
	overflow: hidden;
	transition: background-color 150ms ease;
}
.Footer__maximizeButton_6f1740 {
	background-image: url(maximize.f1d8d0c9.svg);
}
.Footer__minimizeButton_6f1740 {
	background-image: url(minimize.708bf11a.svg);
}
@media (max-height: 500px) {
	.Footer_6f1740 {
		position: absolute;
		left: 15px;
		z-index: 10;
		box-sizing: border-box;
		align-items: stretch;
	}

	.Footer__playbackControl_6f1740 {
		display: flex;
		align-items: center;
	}
}
@media (min-height: 500px) {
	.Footer_6f1740 {
		position: fixed;
		bottom: 0;
		left: 0;
		display: -ms-grid;
		display: grid;
		-ms-grid-columns: 2fr auto 1fr 1fr;
		grid-template-columns: 2fr auto 1fr 1fr;
		    grid-template-areas: 'info playback jump layout';
	}
	.Footer__playbackControl_6f1740 {
		grid-area: playback;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.Footer__infoBox_6f1740 {
		-ms-grid-row: 1;
		-ms-grid-column: 1;
	}
	.Footer__playbackControl_6f1740 {
		-ms-grid-row: 1;
		-ms-grid-column: 2;
	}
	.Footer__jumpTo_6f1740 {
		-ms-grid-row: 1;
		-ms-grid-column: 3;
	}
}
@media (min-height: 500px) {
	.Footer__playbackControl_6f1740 {
		-ms-grid-row: 1;
		-ms-grid-column: 2;
	}
}
@media (max-height: 700px) and (min-height: 500px) {
	.Footer_6f1740 {
		bottom: 15px;
	}
}

.RowLegends__rows_6d0e10 {
	flex: 1;
	position: relative;
}
.RowLegends__row_6d0e10,
.RowLegends__bottomRow_6d0e10 {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 30px;
	width: 100%;
	box-sizing: border-box;
	font-weight: var(--semibold);
	font-size: 20px;
	line-height: 25px;
}
@media (max-height: 700px) {
	.RowLegends__row_6d0e10 {
		font-size: 16px;
		line-height: 20px;
	}
}
@media (max-height: 500px) {
	.RowLegends__row_6d0e10 {
		font-size: 14px;
		line-height: 18px;
	}
}
.RowLegends__rowRemove_6d0e10 {
	position: absolute;
	right: -10px;
	opacity: 0;
}
.RowLegends__row_6d0e10:hover .RowLegends__rowRemove_6d0e10 {
	opacity: 1;
}
.RowLegends__bottomRow_6d0e10 {
	padding-right: 30px;
}

.Timeline_747371 {
	display: flex;
	flex: 1;
	outline: none;
	height: 100%;
	overflow-y: hidden;
	overflow-x: auto;
	margin-bottom: var(--footer-height);
}

.Timeline__sidebar_747371 {
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	flex: 0 0 var(--session-sidebar-width);
	z-index: 10;
	background: var(--background-color);
	display: flex;
	flex-direction: column;
}

.Timeline__sidebarHeader_747371 {
}

.Timeline__sections_747371 {
	display: flex;
	position: relative;
	padding-right: 100px;
}

.ProgressBar_9172e8 {
	flex: 1;
	height: var(--progress-bar-height);
	box-sizing: border-box;
	border: 1px solid #dedede;
	border-radius: 6px;
	overflow: hidden;
}
.ProgressBar__progress_9172e8 {
	width: 100%;
	height: 100%;
	background: #e3e3e3;
}

.SectionHeader_de5b84 {
	display: -ms-grid;
	display: grid;
	    grid-template-areas:
		'title title'
		'description clock'
		'progress progress';
	-ms-grid-rows: 25px 10px 75px 10px 25px;
	grid-template-rows: 25px 75px 25px;
	-ms-grid-columns: 1fr auto;
	grid-template-columns: 1fr auto;
	line-height: 30px;
	row-gap: 10px;
	box-sizing: border-box;
}
.SectionHeader--narrow_de5b84 {
	    grid-template-areas:
		'title'
		'description'
		'clock'
		'progress';
	-ms-grid-rows: 25px 50px 25px 25px;
	grid-template-rows: 25px 50px 25px 25px;
	-ms-grid-columns: auto;
	grid-template-columns: auto;
}
.SectionHeader__titleBar_de5b84 {
	-ms-grid-row: 1;
	-ms-grid-column: 1;
	-ms-grid-column-span: 2;
	grid-area: title;
	display: flex;
	align-items: center;
	font-size: var(--section-title-size);
	cursor: default;
	overflow: hidden;
	grid-gap: 15px;
}
.SectionHeader--narrow_de5b84 > .SectionHeader__titleBar_de5b84 {
	-ms-grid-row: 1;
	-ms-grid-column: 1;
	-ms-grid-column-span: 1;
}
.SectionHeader__title_de5b84 {
	font-size: inherit;
	line-height: 1em;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.SectionHeader__description_de5b84 {
	-ms-grid-row: 3;
	-ms-grid-column: 1;
	grid-area: description;
	font-size: 16px;
	line-height: 25px;
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
}
.SectionHeader--narrow_de5b84 > .SectionHeader__description_de5b84 {
	-ms-grid-row: 2;
	-ms-grid-column: 1;
}
.SectionHeader__clock_de5b84 {
	-ms-grid-row: 3;
	-ms-grid-column: 2;
	grid-area: clock;
	margin-left: 10px;
	font-size: 20px;
	line-height: 25px;
	font-weight: var(--regular);
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}
.SectionHeader--narrow_de5b84 > .SectionHeader__clock_de5b84 {
	-ms-grid-row: 3;
	-ms-grid-column: 1;
}
.SectionHeader__progress_de5b84 {
	-ms-grid-row: 5;
	-ms-grid-column: 1;
	-ms-grid-column-span: 2;
	grid-area: progress;
	display: flex;
	align-items: flex-start;
	--progress-bar-height: 12px;
}
.SectionHeader--narrow_de5b84 > .SectionHeader__progress_de5b84 {
	-ms-grid-row: 4;
	-ms-grid-column: 1;
	-ms-grid-column-span: 1;
}
@media (max-height: 700px) {
	.SectionHeader_de5b84 {
		    grid-template-areas:
			'title clock'
			'description '
			'progress progress ';
		-ms-grid-rows: 25px 5px 25px;
		grid-template-rows: 25px 25px;
		row-gap: 5px;
	}
	.SectionHeader__progress_de5b84 {
		padding-bottom: 20px;
	}
	.SectionHeader__description_de5b84 {
		-ms-grid-row: 3;
		-ms-grid-column: 1;
		grid-area: description;
		font-size: 12px;
		line-height: 25px;
		overflow: hidden;
		text-overflow: ellipsis;
		position: relative;
	}
	.SectionHeader--narrow_de5b84 > .SectionHeader__description_de5b84 {
		-ms-grid-row: 2;
		-ms-grid-column: 1;
	}
	@media (max-height: 700px) {
	.SectionHeader__description_de5b84 {
			-ms-grid-row: 3;
			-ms-grid-column: 1;
	}
	}
	.SectionHeader__titleBar_de5b84 {
		-ms-grid-row: 1;
		-ms-grid-column: 1;
		-ms-grid-column-span: 1;
	}
	.SectionHeader__description_de5b84 {
		-ms-grid-row: 3;
		-ms-grid-column: 1;
	}
	.SectionHeader__clock_de5b84 {
		-ms-grid-row: 1;
		-ms-grid-column: 2;
	}
	.SectionHeader__progress_de5b84 {
		-ms-grid-row: 5;
		-ms-grid-column: 1;
		-ms-grid-column-span: 2;
	}
}

.SectionGrid_736c6e {
	--grid-text-height: 15px;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
.SectionGrid__legend_736c6e {
	position: absolute;
	height: var(--grid-text-height);
	top: 0;
	text-align: center;
	transform: translateX(-50%);
	font-size: 12px;
	font-weight: var(--semibold);
	line-height: 10px;
	color: var(--grid-legend-color);
}
.SectionGrid__line_736c6e {
	position: absolute;
	height: calc(100% - var(--grid-text-height));
	top: var(--grid-text-height);
	width: 1px;
	background: var(--grid-line-color);
}

.Slot_17001c {
	--handle-width: 10px;
	--handle-space: 10px;
	border-radius: var(--slot-radius);
	box-sizing: border-box;
	display: block;
	align-items: stretch;
	pointer-events: all;
	border: 1px solid transparent;
}

.Slot__duration_17001c {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: var(--black);
	font-weight: var(--semibold);
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	text-shadow: 0 0.5px 0.25px rgba(0, 0, 0, 0.2);
}

.Slot__text_17001c {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: var(--black);
	font-weight: var(--regular);
	font-size: 14px;
	line-height: 15px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	text-shadow: 0 0.5px 0.25px rgba(0, 0, 0, 0.2);
}

.Slot__countdown_17001c {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: var(--darkgray);
	font-weight: var(--bold);
	font-size: 20px;
	line-height: 20px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.Slot__handle_17001c {
	opacity: 0;
	transition: opacity 150ms ease;
	position: relative;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	position: absolute;
	top: 0;
	height: 100%;
}

.Slot_17001c:hover .Slot__handle_17001c {
	opacity: 1;
}

.Slot__handle--middle_17001c {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	font: var(--semibold) 15px/1em var(--sans);
	text-align: center;
	cursor: move;
	grid-area: middle;
}

.Slot__handle--middle_17001c:hover {
	text-decoration: none;
}

.Slot__handle--start_17001c,
.Slot__handle--end_17001c {
	cursor: ew-resize;
	border-radius: var(--slot-radius);
}

.Slot__handle--start_17001c {
}

.Slot__handle--end_17001c {
}

.Slot__handle--start_17001c:before,
.Slot__handle--end_17001c:before {
	content: '';
	display: block;
	position: absolute;
	margin-top: var(--handle-space);
	margin-bottom: var(--handle-space);
	width: var(--handle-width);
	height: calc(100% - var(--handle-space) * 2);
	border-radius: calc(var(--handle-width) / 2);
	background: var(--white);
	border: 0.5px solid var(--midgray);
}

.Slot__handle--start_17001c:before {
	left: 10px;
}

.Slot__handle--end_17001c:before {
	right: 10px;
}

.Slot__progressWrap_17001c {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: var(--slot-radius);
}

.Slot__progress_17001c {
	width: 100%;
	height: 100%;
}

.Slot__background_17001c {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	border-radius: var(--slot-radius);
	overflow: hidden;
	opacity: 0.2;
}

.Slot__backgroundStripe_17001c {
	position: absolute;
	width: 100%;
}

.TimelinePlayhead_083eca {
	--playhead-hitarea-width: 20px;
	--playhead-width: 4px;
	position: absolute;
	left: calc(0px - var(--playhead-hitarea-width));
	padding: 0 var(--playhead-hitarea-width);
}
.TimelinePlayhead__bar_083eca {
	width: var(--playhead-width);
	height: 100%;
	top: 0;
	background: var(--playhead-color);
	border-radius: calc(var(--playhead-width) / 2);
}

.TimelineHoverSlot_0d205b {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.TimelineHoverSlot__slot_0d205b {
	border-radius: var(--slot-radius);
	border: var(--slot-border-width) solid transparent;
	box-sizing: border-box;
	background-image: url(slot-add.5e649bae.svg);
	background-size: 26px auto;
	background-position: center center;
	background-repeat: no-repeat;
}

.Section_252ac0 {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 0 0 auto;
	position: relative;
	padding-left: 30px;
	padding-right: 30px;
}

.Section__main_252ac0 {
	position: relative;
}

.Section__slots_252ac0 {
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.BlankSection_252ac0 {
	display: flex;
	flex-direction: column;
	flex: 0 0 auto;
	color: var(--action-link-color);
	display: flex;
	align-items: stretch;
	margin: auto 50px;
	max-height: 60%;
}

.BlankSection__main_252ac0 {
	box-shadow: var(--shadow-clickable);
	border: 1px solid var(--border-color);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.BlankSection__addIcon_252ac0 {
	width: 54px;
	height: 54px;
	background-size: 54px auto;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url(large-plus.b5b71e30.svg);
}

.BlankSection__prompt_252ac0 {
	font-size: 20px;
	margin-top: 30px;
	font-weight: var(--semibold);
}

.SectionSeparator_8eff84 {
	width: 140px;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.SectionSeparator__ruler_8eff84 {
	width: 1px;
	flex: 1;
	background: var(--divider-color);
	max-height: 100px;
}
.SectionSeparator__content_8eff84 {
	margin: 20px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: 15px;
}
.SectionSeparator__label_8eff84 {
	font: var(--label-medium);
}
.SectionSeparator__nextButton_8eff84 {
	width: 48px;
	height: 48px;
}

.SessionDetails_aa0eb4 {
	font-size: 14px;
	height: 90vh;
	overflow: hidden;
}

.SessionDetails__header__cta_aa0eb4 {
	display: flex;
	flex-direction: row;
}

.SessionDetails__general_aa0eb4 {
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
}

.SessionDetails__general__content_aa0eb4 {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	height: 72px;
	max-height: 72px;
	border-bottom: 1px solid #f2f2f2;
}

.SessionDetails__general__content_title_aa0eb4 {
	width: 25%;
}

.SessionDetails__general__content_title_aa0eb4 span {
	margin-inline: 11px;
	font-size: 14px;
	color: #808080;
	font-weight: 500;
}

.SessionDetails__general__content_desc_aa0eb4 {
	width: 75%;
	display: flex;
	flex-direction: row;
	color: #4d4d4d;
	font-size: 14px;
	font-weight: 600;
}

.SessionDetails__general__content_title_aa0eb4,
.SessionDetails__general__content_desc_aa0eb4 {
	min-height: 65px;
	display: flex;
	align-items: center;
}

.SessionDetails__general__footer_aa0eb4 {
	margin-top: 2px;
	width: 100%;
}

.SessionDetails__agenda_aa0eb4 {
	margin-top: 15px;
	box-sizing: border-box;
	border: 1px solid #f2f2f2;
	flex-grow: 1;
	border-radius: 10px;
	overflow: auto;
}

.SessionDetails__agenda__container_aa0eb4 {
	padding-block: 24px;
}

.SessionDetails__agenda__container_aa0eb4 h2 {
	text-align: start;
	padding-inline: 24px;
	font-weight: 600;
	font-size: 16px;
	color: #4d4d4d;
}

.SessionDetails__agenda__container_aa0eb4 p {
	margin-bottom: 8px;
	font-weight: 500;
	font-size: 12px;
	color: #808080;
}

.SessionDetais__agenda__content_aa0eb4 {
}

.SessionDetails__agenda__content__section_aa0eb4 {
	padding: 24px;
	position: relative;
}

.SessionDetails__agenda__content__section__border_aa0eb4 {
	border-bottom: 1px solid #f2f2f2;
	width: 90%;
	position: absolute;
	bottom: 0;
}

.SessionDetails__agenda__content__section__time_header_aa0eb4 {
	display: flex;
	flex-direction: row;
}

.SessionDetails__agenda__content__section__time_header_aa0eb4 div {
	display: inline-flex;
	align-items: center;
}

.SessionDetails__agenda__content__section__time_header__duration_aa0eb4 {
	background-color: #f8f8f8;
	width: 60px;
	height: 32px;
	justify-content: center;
	padding: 0px 5px;
	margin-right: 8px;
	font-weight: 600;
	font-size: 12px;
	color: #616161;
}

.SessionDetails__agenda__content__section__time_header__guests_aa0eb4 div {
	box-sizing: border-box;
	height: 28px;
	width: 28px;
	border-radius: 50% !important;
	border: 2px solid white;
	background-color: #f8f8f8;
	font-weight: 500;
	font-size: 12px;
	color: #616161;
	justify-content: center;
}

.SessionDetails__agenda__content__section__subject_aa0eb4 {
}

.SessionDetails__agenda__content__section__subject_aa0eb4 h3 {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #4d4d4d;
	margin-bottom: 4px;
}

.SessionDetails__agenda__content__section__subject_aa0eb4 p {
	font-weight: 500;
	font-size: 12px;
	color: #808080;
}

.SessionDetails__avatar_aa0eb4 {
	cursor: pointer;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center !important;
	align-items: center;
	padding: 8px;
	width: 32px !important;
	height: 32px !important;
	border-radius: 100px;
	color: white;
}

.SessionDetails__meetingHost_aa0eb4 {
	margin-left: -14px;
	background-size: cover;
	width: 138px;
	height: 24px;
	background-position: center center;
	background-repeat: no-repeat;
}

.google_aa0eb4 {
	background-image: url(google-meeting.d88b2a39.svg);
	background-size: contain;
}

.zoom_aa0eb4 {
	background-image: url(zoom-meeting.a3e5fed8.svg);
	background-size: contain;
}

.btn_aa0eb4 {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #000000;
	color: #fff;
}

.btn_aa0eb4:hover {
	background-color: #000;
	color: white;
}

.SessionDetails__next_btn_aa0eb4 {
	margin-inline: auto;
	margin-top: 34px;
	margin-bottom: 23px;
	width: 200px;
	height: 52px;
	border-radius: 2px;
}

.SessionDetails__join_btn_aa0eb4 {
	border-radius: 4px;
	box-sizing: border-box;
	margin-left: 10%;
	width: 85px !important;
	max-height: 40px !important;
}

.SessionDetails__join_btn_aa0eb4 span {
	font-size: 14px;
}

.SessionDetails__join_btn_aa0eb4 img {
	transform: translate(-5px, 3px);
}

.SessionDetails__join_btn_aa0eb4 span:hover {
	background-color: #000 !important;
}

.SessionDetails__agenda__goal_objective_aa0eb4 {
	margin-bottom: 25px !important;
}

.SessionDetails__agenda__objective_title_aa0eb4 {
	margin-bottom: 10px !important;
}

.SessionDetails__agenda__objective_desc_aa0eb4 {
	padding-inline: 24px;
}

@media (max-width: 500px) {
	.SessionDetails__join_btn_aa0eb4 {
		margin-left: 26%;
	}
}

@media (max-width: 400px) {
	.SessionDetails__join_btn_aa0eb4 {
		margin-left: 40%;
	}
}

@media (max-height: 500px), (max-width: 600px) {
	.SessionDetails__next_btn_aa0eb4 {
		display: none;
	}
}

.Session_d072b2 {
	--session-sidebar-width: 200px;
	--section-header-height: 100px;
	--session-header-height: 50px;
	--session-footer-height: 100px;
	--slot-border-width: 3px;
	--session-header-bottom-space: 30px;
	--section-title-size: 25px;
	--section-clock-size: 20px;
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}
@media (max-height: 700px) {
	.Session_d072b2 {
		--content-space-top: 20px;
		--session-header-bottom-space: 10px;
		--section-title-size: 18px;
		--section-clock-size: 16px;
	}
}
.Session__addSectionButton_d072b2:hover {
	opacity: 1;
}

.SessionPage_502e68 {
	--content-width: 100%;
	--content-space-top: 20px;
	--page-pad-x: 0;
	align-items: stretch;
	box-sizing: border-box;
	max-height: 100%;
}

@media (max-height: 700px) {
	.SessionPage_502e68 {
		--content-space-top: 20px;
	}
}

.ManageSessionsPage_f5342e {
	--content-space-top: 5vh;
}
.ManageSessionsPage__sessions_f5342e {
	max-width: 535px;
	flex: 1;
}
.ManageSessionsPage__goBack_f5342e {
	margin-bottom: 5px;
}
.ManageSessionsPage__title_f5342e {
	font: var(--title-large);
	margin-bottom: 60px;
}

.ActiveUsersList_654a67 {
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: left;
}
.ActiveUsersList--preview_654a67 {
}
.ActiveUsersList--full_654a67 {
	display: flex;
	flex-direction: column;
}
.ActiveUsersListItem_654a67 {
	border-radius: 15px;
	transition: background-color 150ms ease, box-shadow 150ms ease;
	margin-left: calc(0px - var(--space-x));
	margin-right: calc(0px - var(--space-x));
}
.ActiveUsersListItem--preview_654a67 {
	--title-font: var(--title-tiny);
	--info-font: var(--body-small);
	--space-x: 20px;
	--space-y: 15px;
}
.ActiveUsersListItem--full_654a67 {
	--title-font: var(--title-small);
	--info-font: var(--body-regular);
	--space-x: 30px;
	--space-y: 20px;
	row-gap: 10px;
}
.ActiveUsersListItem--preview_654a67 + .ActiveUsersListItem--preview_654a67 {
	margin-top: 10px;
}
.ActiveUsersListItem--full_654a67 + .ActiveUsersListItem--full_654a67 {
	margin-top: 20px;
}
.ActiveUsersListItem_654a67:hover {
}
.ActiveUsersListItem__link_654a67:after {
	content: '';
	grid-row: 1 / -1;
	-ms-grid-column: 2;
	grid-column: 2;
	display: inline-block;
	width: 30px;
	height: 100%;
	background-size: 14px auto;
	background-repeat: no-repeat;
	background-position: center right;
	background-image: url(chevron-right.5e090e2e.svg);
	transition: transform 150ms ease;
}
.ActiveUsersListItem__link_654a67:hover:after {
	transform: translateX(3px);
}
.ActiveUsersListItem__link_654a67 {
	text-decoration: none;
	display: -ms-grid;
	display: grid;
	-ms-grid-rows: auto auto auto;
	grid-template-rows: auto auto auto;
	-ms-grid-columns: 1fr auto;
	grid-template-columns: 1fr auto;
	padding: var(--space-y) var(--space-x);
	gap: inherit;
	cursor: pointer;
}
.ActiveUsersListItem__link_654a67:hover {
	text-decoration: none;
}
.ActiveUsersListItem__name_654a67 {
	text-align: left;
	font: var(--title-font);
	-ms-grid-row: 1;
	grid-row: 1;
	-ms-grid-column: 1;
	grid-column: 1;
}
.ActiveUsersListItem__email_654a67 {
	font: var(--info-font);
	-ms-grid-row: 2;
	grid-row: 2;
	-ms-grid-column: 1;
	grid-column: 1;
}
.ActiveUsersListItem__actions_654a67 {
	-ms-grid-row: 3;
	grid-row: 3;
	-ms-grid-column: 1;
	grid-column: 1;
	cursor: default;
	display: flex;
	grid-gap: 20px;
}
.ActiveUsersListItem__actionItem_654a67 {
}

.PendingUsersList_bde321 {
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: left;
}
.PendingUsersList--preview_bde321 {
}
.PendingUsersList--full_bde321 {
	display: flex;
	flex-direction: column;
}
.PendingUsersListItem_bde321 {
	border-radius: 15px;
	transition: background-color 150ms ease, box-shadow 150ms ease;
	margin-left: calc(0px - var(--space-x));
	margin-right: calc(0px - var(--space-x));
}
.PendingUsersListItem--preview_bde321 {
	--title-font: var(--title-tiny);
	--info-font: var(--body-small);
	--space-x: 20px;
	--space-y: 15px;
}
.PendingUsersListItem--full_bde321 {
	--title-font: var(--title-small);
	--info-font: var(--body-regular);
	--space-x: 30px;
	--space-y: 20px;
	row-gap: 10px;
}
.PendingUsersListItem--preview_bde321 + .PendingUsersListItem--preview_bde321 {
	margin-top: 10px;
}
.PendingUsersListItem--full_bde321 + .PendingUsersListItem--full_bde321 {
	margin-top: 20px;
}
.PendingUsersListItem_bde321:hover {
}
.PendingUsersListItem__link_bde321:after {
	content: '';
	grid-row: 1 / -1;
	-ms-grid-column: 2;
	grid-column: 2;
	display: inline-block;
	width: 30px;
	height: 100%;
	background-size: 14px auto;
	background-repeat: no-repeat;
	background-position: center right;
	background-image: url(chevron-right.5e090e2e.svg);
	transition: transform 150ms ease;
}
.PendingUsersListItem__link_bde321:hover:after {
	transform: translateX(3px);
}
.PendingUsersListItem__link_bde321 {
	text-decoration: none;
	display: -ms-grid;
	display: grid;
	-ms-grid-rows: auto auto auto;
	grid-template-rows: auto auto auto;
	-ms-grid-columns: 1fr auto;
	grid-template-columns: 1fr auto;
	padding: var(--space-y) var(--space-x);
	gap: inherit;
	cursor: pointer;
}
.PendingUsersListItem__link_bde321:hover {
	text-decoration: none;
}
.PendingUsersListItem__name_bde321 {
	text-align: left;
	font: var(--title-font);
	-ms-grid-row: 1;
	grid-row: 1;
	-ms-grid-column: 1;
	grid-column: 1;
}
.PendingUsersListItem__email_bde321 {
	font: var(--info-font);
	-ms-grid-row: 2;
	grid-row: 2;
	-ms-grid-column: 1;
	grid-column: 1;
}
.PendingUsersListItem__actions_bde321 {
	-ms-grid-row: 3;
	grid-row: 3;
	-ms-grid-column: 1;
	grid-column: 1;
	cursor: default;
	display: flex;
	grid-gap: 20px;
}
.PendingUsersListItem__actionItem_bde321 {
}

.UsersListPage_3cff1d {
	--content-space-top: 5vh;
}
.UsersListPage__sessions_3cff1d {
	max-width: 535px;
	flex: 1;
}
.UsersListPage__goBack_3cff1d {
	margin-bottom: 5px;
}
.UsersListPage__title_3cff1d {
	font: var(--title-large);
	margin-bottom: 60px;
}

.WaitingApproval_510295 {
	--content-space-top: 15vh;
	text-align: center;
}

.WaitingApproval__title_510295 {
	font: var(--title-medium);
	margin-bottom: 60px;
}

.WaitingApproval__info_510295 {
	margin-bottom: 60px;
}

.WaitingApproval__info_510295 p {
	font: var(--body-small);
}

.WaitingApproval__link_510295{}
.LandingPage__container_d0914a {
	position: relative;
	max-width: 1100px;
	width: 100%;
	height: 100%;
}

.LandingPage_d0914a {
	display: flex;
    flex-direction: column;
    align-items: center;
	margin: 0;
	font-family: sans-serif;
	color: #020776;
	height: 100vh;
	overflow: hidden;
	background: linear-gradient(
	  289deg,
	  rgba(205, 185, 255, 1) 0%,
	  rgba(92, 217, 255, 1) 21%,
	  rgba(178, 255, 163, 1) 43%,
	  rgba(212, 255, 187, 1) 70%,
	  rgba(120, 227, 255, 1) 100%
	);
	padding: 0px 120px 0px 120px;
}

.LandingPage__header_d0914a {
	position:absolute;
	top: 4%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 2px solid #1D1166;
	border-radius: 5px;
	width: 100%;
	height: 6.8vh;
}

.LandingPage__header_d0914a h2{
	font-weight: 700;
	font-size: 18px;
	margin: 0px 2%;
}

.LandingPage__logo_d0914a {
	color: #1D1166
}

.LandingPage__login_d0914a {
	cursor:pointer !important;
	z-index: 1 !important;
}

.LandingPage__login_d0914a span {
	color: rgba(29, 17, 102, 1) !important;
}

.LandingPage__section_d0914a {
	position:relative;
	height: 100%;
	display: flex;
	color: #1D1166;
}

.LandingPage__left_content_d0914a {
	max-width: 400px;
	min-width: 38vw;
	padding: 13px;
	-ms-grid-row-align: center;
	    align-self: center;
}

.LandingPage__left_content_d0914a h1{
	font-family: 'Work Sans', sans-serif;
	font-weight: 800;
	font-size: 38px;
	font-style: normal;
    letter-spacing: .01em;
    text-transform: none;
	margin-bottom: 30px;
}

.LandingPage__description_d0914a {
	width: 82%;
}

.LandingPage__left_content_d0914a h2 {
	font-size: 24px;
    font-weight: 500;
    text-align:start;
	line-height:27px;
	margin-bottom: 25px;
}

.LandingPage__buttons_get_started_d0914a{
	background-color: white; /* Green */
	color: #1D1166;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 64px;
	font-size: 18px;
	font-weight: bold;
	min-width: 40%;
	max-width: 40%;
	min-height: 58px;
	max-height: 58px;
	border-radius: 0 !important;
}

.LandingPage__buttons_d0914a span {
	color: #1D1166;
}

.LandingPage__right_content_d0914a {
}

.LandingPage__footer_d0914a {
	min-height: 11vh;
	display: flex;
	align-items: center;
	border-radius: 5px;
	border: 2px solid #1D1166;
	justify-content: space-around;
	background: rgba(255,255,255,0.3);
	position: absolute;
	bottom: 4%;
	width: 100%;
}

.LandingPage__footer_d0914a h2{
	font-size: 18px;
	font-weight: 800;
	cursor:pointer;
}

.LandingPage__footer_d0914a a {
	text-decoration: none !important;
}

.LandingPage__dialog_d0914a {
	position: relative;
	background-color: white;
	max-height: 70vh;
	min-height: 30vh;
	border-radius: 10px;
	padding: 30px;
	text-align: center;
	color: #000;
	overflow: auto;
  }

.LandingPage__dialog_close_d0914a {
	position: absolute;
	top: 20px;
	right: 20px;
	font-weight: 800;
	font-size: 18px;
	cursor: pointer;
  }

.LandingPage__dialog_head_d0914a {
	margin: 30px 0px 30px 0px;
	font-size: 18px;
  }

.LandingPage__dialog_content_d0914a {
	padding: 2px 50px 2px 50px;
	font-size: 18px;
  }

@media only screen and (max-width: 900px) {
	.LandingPage_d0914a {
		padding: 2px 20px 0px 20px;
	  }

	.LandingPage__description_d0914a {
		padding-right: 0px;
	}

	.LandingPage__description_d0914a h2 {
		text-align: center;
	}

	  .LandingPage__left_content_d0914a {
		width: 100%;
		min-width: 0px;
		max-width: 100%;
		text-align: center;
	  }

	  .LandingPage__right_content_d0914a {
		display: none;
	  }

	  h1 ,
	  h2 {
		text-align: center;
	  } 

	  .LandingPage__description_d0914a {
		width: 100%;
	}	

	  .LandingPage__buttons_d0914a button {
		width: 100%;
	  }

	  .LandingPage__header_d0914a h2 {
		font-size: 18px;
	  }

	  .LandingPage__footer_d0914a {
	  display: flex;
	  align-items: center;
	}

	.LandingPage__footer_d0914a h2 {
		font-size: 14px;
	  }

	  .LandingPage__left_content_d0914a h1 {
		font-size: 30px;
	  }

	  .LandingPage__left_content_d0914a h2 {
		font-size: 24px;
		font-weight: lighter;
	  }
	  
	  .LandingPage__buttons_get_started_d0914a {
		max-width: 100%;
	  }
}

.LandingPage__container_6f7cae {
	position: relative;
	max-width: 1100px;
	width: 100%;
	height: 100%;
}

.LandingPage_6f7cae {
	display: flex;
    flex-direction: column;
    align-items: center;
	margin: 0;
	font-family: sans-serif;
	color: #020776;
	background: white;
	padding: 0px 120px 0px 120px;
}

.LandingPage__header_6f7cae {
	position:absolute;
	top: 4%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 2px solid #1D1166;
	border-radius: 5px;
	width: 100%;
	height: 6.8vh;
}

.LandingPage__header_6f7cae h2 {
	font-family: 'Work Sans', sans-serif;
	font-weight: 700;
	font-size: 18px;
	margin: 0px 2%;
}

.LandingPage__logo_6f7cae {
	color: #1D1166
}

.LandingPage__login_6f7cae span {
	color: rgba(29, 17, 102, 1) !important;
}

.LandingPage__section_6f7cae {
	position:relative;
	color: #1D1166;
}

.LandingPage__left_content_6f7cae {
	margin-top: 150px;
	padding: 13px;
	-ms-grid-row-align: center;
	    align-self: center;
}

.LandingPage__left_content_6f7cae h1{
	font-family: 'Work Sans', sans-serif;
	font-weight: 800;
	font-size: 38px;
	font-style: normal;
    letter-spacing: .01em;
    text-transform: none;
	margin-bottom: 30px;
}

.LandingPage__description_6f7cae {
	width: 82%;
}

.LandingPage__left_content_6f7cae h2 {
	font-size: 24px;
    font-weight: 500;
    text-align:start;
	line-height:27px;
	margin-bottom: 25px;
}

.LandingPage__buttons_6f7cae span {
	color: #1D1166;
}

.LandingPage__right_content_6f7cae {
	max-width: 400px;
	min-width: 38vw;
	padding: 13px;
	-ms-grid-row-align: center;
	    align-self: center;
}

.LandingPage__right_content_6f7cae h1 {
	font-family: 'Work Sans', sans-serif;
	font-size: 22px; 
	font-weight: 700;
}

.LandingPage__footer_6f7cae h2 {
	font-size: 18px;
	font-weight: 800;
	cursor:pointer;
}

.LandingPage__footer_6f7cae a {
	text-decoration: none !important;
}

@media only screen and (max-width: 900px) {
	.LandingPage_6f7cae {
		padding: 2px 20px 0px 20px;
	  }

	.LandingPage__description_6f7cae {
		padding-right: 0px;
	}

	.LandingPage__description_6f7cae h2 {
		text-align: center;
	}

	  .LandingPage__left_content_6f7cae {
		width: 100%;
		min-width: 0px;
		max-width: 100%;
		text-align: center;
		padding: 0px;
	  }

	  h1 ,
	  h2 {
		text-align: center;
	  } 

	  .LandingPage__description_6f7cae {
		width: 100%;
	}	

	  .LandingPage__buttons_6f7cae button {
		width: 100%;
	  }

	  .LandingPage__header_6f7cae h2 {
		font-size: 18px;
	  }

	.LandingPage__footer_6f7cae h2 {
		font-size: 14px;
	  }

	  .LandingPage__left_content_6f7cae h1 {
		font-size: 30px;
	  }

	  .LandingPage__left_content_6f7cae h2 {
		font-size: 24px;
		font-weight: lighter;
	  }
}

/*REMOVE PLEASE JUST FOR TESTING*/

.AuthPage__message_6f7cae {
	margin-top: 1em;
	min-height: 2em;
	font-size: 20px;
	text-align: center;
}

.AuthPage__info_6f7cae p {
	text-align: center;
	font-size: 16px;
}

.AuthPage__error_6f7cae {
	margin-top: 1em;
}

.AuthPage__actions_6f7cae {
	display: flex;
	align-items: center;
	-moz-column-gap: 15px;
	     column-gap: 15px;
}

.AuthPage__actions_6f7cae button {
	background-color: #020776 !important;
	font-weight: 800;
	width: 100%;
}

.AuthPage__actions_6f7cae button span {
	color: white;
	font-weight: 800;
}

.AuthPage__input_6f7cae {
	display: block;
	margin-bottom: 1em;
}

/*END*/
.WaitingApproval_275ac6 {
	--content-space-top: 15vh;
	text-align: center;
}

.WaitingApproval__title_275ac6 {
	font: var(--title-medium);
	margin-bottom: 60px;
}

.WaitingApproval__info_275ac6 {
	margin-bottom: 60px;
}

.WaitingApproval__title_275ac6 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    color: #020776;
}

.WaitingApproval__info_275ac6 p {
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    color: #020776;
}
/*# sourceMappingURL=index.dc0e7b69.css.map */
