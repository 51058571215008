:root {
	--black: #000000;
	--red: #f00;
	--off-black: #1d1d1d;
	--white: #ffffff;
	--off-white: #fcfcfc;
	--gray: #f1f1f1;
	--gray-hover: #ededed;
	--midgray: #c4c4c4;
	--darkgray: #666666;
	--icongray: #ababab;

	--primary-color: #e4f6f3;
	--primary-color-hover: #d5f1ec;
	--primary-color-fg: var(--black);
	--secondary-color: #f1f1f1;
	--secondary-color-hover: #ededed;
	--secondary-color-fg: var(--black);
	--danger-color: #f0abab;
	--danger-color-hover: #ed9797;
	--danger-color-fg: var(--black);
	--link-color: #243633;

	--logo-color: var(--black);
	--background-color: var(--white);
	--text-color: var(--black);
	--divider-color: rgba(130, 130, 130, 0.3);
	--action-link-color: var(--darkgray);
	--label-color: var(--off-black);
	--default-color: var(--gray);
	--default-color-hover: var(--gray-hover);
	--button-text-color: var(--black);
	--button-text-color-hover: var(--off-black);
	--playhead-color: #d9d9d9;
	--border-color: #dedede;
	--placeholder-color: rgba(127, 127, 127, 0.6);
	--field-focus-color: var(--midgray);
	--grid-line-color: rgba(170, 170, 170, 0.22);
	--grid-legend-color: rgba(0, 0, 0, 0.5);

	--regular: 400;
	--semibold: 500;
	--bold: 600;

	--medium-page-width: 1040px;
	--content-space-top: 0px;
	--content-width: var(--medium-page-width);
	--page-pad-x: 40px;
	--header-height: 100px;
	--footer-height: 120px;
	--slot-radius: 10px;
	--button-large-height: 60px;
	--button-size: 25px;

	--sans: 'Roboto', system-ui, sans-serif;
	--title-large: var(--semibold) 50px/55px var(--sans);
	--title-medium: var(--semibold) 30px/40px var(--sans);
	--title-small: var(--semibold) 20px/20px var(--sans);
	--title-tiny: var(--semibold) 16px/20px var(--sans);
	--body-large: var(--regular) 20px/1.6 var(--sans);
	--body-regular: var(--regular) 16px/25px var(--sans);
	--body-small: var(--regular) 14px/25px var(--sans);
	--body-tiny: var(--regular) 14px/15px var(--sans);
	--footer-font: var(--semibold) 16px/20px var(--sans);
	--clock-font: var(--semibold) 16px/20px var(--sans);
	--participant-clock-font: var(--semibold) 30px/30px var(--sans);
	--label-medium: var(--title-tiny);
	--label-small: var(--regular) 14px/20px var(--sans);

	--shadow-clickable: 0px 0.5px 1px rgba(0, 0, 0, 0.25);

	--z-app-header: 200;
	--z-overlay-editor: 500;
}

@media (max-height: 700px) {
	:root {
		--footer-height: 80px;
		--header-height: 60px;
		--button-large-height: 44px;
	}
}
@media (max-height: 500px) {
	:root {
		--footer-height: 10px;
	}
}

html,
body {
	margin: 0;
	padding: 0;
	font: var(--body-regular);
	height: 100%;
	color: var(--text-color);
	line-height: 1.5;
	background: var(--background-color);
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

:global(#app) {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

a {
	color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	line-height: 1;
	font-weight: var(--semibold);
}
p {
	margin: 0;
}
ul,
li {
	margin: 0;
	padding: 0;
	list-style-position: inside;
}
pre {
	tab-size: 2;
}

::-webkit-scrollbar {
	background: lightgray !important;
	border-radius: 10px !important;
	width: 5px !important;
	height: 5px !important;
}
::-webkit-scrollbar-thumb {
	background: #000 !important;
	border-radius: 10px !important;
	width: 5px !important;
	height: 5px !important;
}
